import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUserSlash } from '@fortawesome/pro-duotone-svg-icons/faUserSlash'

import AysncSelect from 'react-select/async'
import Avatar from '../Elements/Avatar.tsx'

const singleErrorStyles = {
    control: (provided, state) => {
        return {
            ...provided,
            borderColor: '#dc3545',
            boxShadow: state.isFocused
                ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
                : 'transparant',
            ':hover': { borderColor: '#dc3545' },
        }
    },
}

class UserField extends React.Component {
    constructor(props) {
        super(props)

        this.innerRef = React.createRef()

        let selected = []
        if (this.props.value && this.props.value.length > 0) {
            this.props.value.map((item) => selected.push(item))
        }

        let defaultCurrent =
            this.props.item &&
            this.props.item.settings &&
            this.props.item.settings.default_current_user === true &&
            this.props.mode !== 'search' &&
            this.props.mode !== 'advanced-search'
                ? true
                : false

        if (defaultCurrent && this.props.isNewDossier) {
            selected.push({
                id: this.props.kedo.user().getUserId(),
                username: this.props.kedo.user().getUsername(),
            })
        }

        let displayNameStyle =
            this.props.item &&
            this.props.item.settings &&
            this.props.item.settings.display_name_style === true &&
            this.props.mode !== 'search' &&
            this.props.mode !== 'advanced-search'
                ? true
                : false

        this.state = {
            default_current_user: defaultCurrent,
            display_name_style: displayNameStyle,
            options: [],
            optionsLoading: false,
            showInactive: true,
            page: 0,
            search: '',
            selected: selected,
            total: 0,
            value: this.props.value,
        }

        this.doFocus = this.doFocus.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.getOptions = this.getOptions.bind(this)
    }

    onCancel() {
        this.setState({ selected: this.props.value })
    }

    doFocus() {
        setTimeout(() => this.innerRef.current.focus(), 1)
        window.scrollTo(0, this.innerRef.current.offsetTop)
    }

    getValue() {
        return this.state.selected.map((item) => item.id)
    }

    userDisplay(item) {
        if (
            this.props.item.settings &&
            this.props.item.settings.display_name_style === 'avatar'
        ) {
            return (
                <Avatar
                    key={item}
                    kedo={this.props.kedo}
                    user={{
                        userId: item.id,
                        username: item.text,
                    }}
                    options={{
                        size: 25,
                        showPresence: false,
                    }}
                />
            )
        } else if (
            this.props.item.settings &&
            this.props.item.settings.display_name_style === 'both'
        ) {
            return (
                <>
                    <Avatar
                        key={item}
                        kedo={this.props.kedo}
                        user={{
                            userId: item.id,
                            username: item.text,
                        }}
                        options={{
                            size: 25,
                            showPresence: false,
                        }}
                    />
                    &nbsp;
                    {item.text}
                </>
            )
        }

        return item.text
    }

    renderShow() {
        if (!this.state.selected || this.state.selected.length <= 0) {
            return null
        }

        if (this.state.selected.length === 1) {
            return (
                <span>
                    {this.state.selected.map((item) => (
                        <a key={item.id} href={`/user/${item.id}`}>
                            {/* {item.text} */}
                            {this.userDisplay(item)}
                        </a>
                    ))}
                </span>
            )
        }

        return (
            <ul>
                {this.state.selected.map((item) => (
                    <li style={{ listStyle: 'none' }} key={item.id}>
                        <a href={`/user/${item.id}`}>
                            {/* {item.text} */}
                            {this.userDisplay(item)}
                        </a>
                    </li>
                ))}
            </ul>
        )
    }

    addSelectedItem(data) {
        let select = []

        if (data) select = Array.isArray(data) ? data : [{ ...data }]

        if (this.props.changeValue) {
            let vals = []

            select.forEach((user) => {
                vals.push({
                    id: user.id,
                    label:
                        user.label && user.label.props
                            ? user.label.props.children[0]
                            : '',
                })
            })

            this.props.changeValue(this.props.item.id, vals)
        }

        this.setState({
            selected: select,
        })
    }

    getOptions(inputValue, callBack) {
        if (!inputValue && callBack) {
            callBack([])
        }

        let params = {
            params: {
                environment: this.props.kedo.env().getEnvironment().id,
                limit: 25,
            },
        }

        if (inputValue && inputValue.length > 0) params.params.name = inputValue

        if (this.state.showInactive !== true) {
            params.params.active = true
        }

        this.props.kedo
            .api()
            .get(this.props.kedo.api().getUserEndpoint(), params)
            .then((response) => {
                const tempArray = []
                response.data.results.forEach((user) => {
                    tempArray.push({
                        id: user.id,
                        value: user.id,
                        label: (
                            <span
                                style={
                                    !user.environment_user_active
                                        ? { color: 'gray' }
                                        : null
                                }
                            >
                                <Avatar
                                    key={user}
                                    kedo={this.props.kedo}
                                    user={{
                                        userId: user.id,
                                        username: user.username,
                                    }}
                                    options={{
                                        size: 25,
                                        showPresence: false,
                                    }}
                                />
                                &nbsp;{this.getName(user)}
                                {!user.environment_user_active ? (
                                    <FontAwesomeIcon icon={faUserSlash} />
                                ) : null}
                            </span>
                        ),
                    })
                })

                if (callBack) callBack(tempArray)
            })
    }

    isSingleSelect() {
        let settings = this.props.item.def_dossier_def_field.def_field.settings
        if (settings && settings.multiple && settings.multiple === true) {
            return false
        }

        return true
    }

    getName(item) {
        if (item.text) {
            return item.text
        }

        if (item.label) {
            return item.label
        }

        if (!item.profile) {
            return item.username
        }

        return (
            item.profile.last_name +
            ', ' +
            item.profile.first_name +
            (item.profile.preposition && item.profile.preposition.length > 0
                ? ' ' + item.profile.preposition
                : '')
        )
    }

    isInvalid() {
        if (this.props.errors && this.props.errors.length > 0) {
            return true
        }

        return false
    }

    renderEdit() {
        let defaultValue = this.state.selected
            ? this.state.selected.map((item) => {
                  return {
                      id: item.id,
                      value: item.id,
                      label: this.getName(item),
                  }
              })
            : null

        return (
            <>
                <AysncSelect
                    inputClassName={'form-control form-control-sm'}
                    noOptionsMessage={() => this.props.kedo.t('No items found')}
                    cacheOptions
                    defaultOptions
                    placeholder={this.props.kedo.t('Search') + '...'}
                    isClearable
                    loadOptions={this.getOptions}
                    isMulti={!this.isSingleSelect()}
                    defaultValue={defaultValue}
                    onChange={(selected) => this.addSelectedItem(selected)}
                    isInvalid={this.isInvalid()}
                    styles={this.isInvalid() ? singleErrorStyles : ''}
                />
                {this.isInvalid() ? (
                    <span className={'fake-feedback'}>{this.props.errors}</span>
                ) : null}
            </>
        )
    }

    renderSearch() {
        if (this.state.value !== this.props.value) {
            this.setState({ value: this.props.value })
        }

        let defaultValue = this.state.selected
            ? this.state.selected.map((item) => {
                  return {
                      id: item.id,
                      value: item.id,
                      label: this.getName(item),
                  }
              })
            : null

        return (
            <>
                <AysncSelect
                    inputClassName={'form-control form-control-sm'}
                    noOptionsMessage={() => this.props.kedo.t('No items found')}
                    cacheOptions
                    defaultOptions
                    placeholder={this.props.kedo.t('Search') + '...'}
                    isClearable
                    loadOptions={this.getOptions}
                    isMulti
                    defaultValue={defaultValue}
                    onChange={(selected) => this.addSelectedItem(selected)}
                />
            </>
        )
    }

    render() {
        if (this.props.mode === 'edit' || this.props.mode === 'show/edit') {
            return this.renderEdit()
        } else if (
            this.props.mode === 'search' ||
            this.props.mode === 'advanced-search'
        ) {
            return this.renderSearch()
        }
        return this.renderShow()
    }
}

export default UserField
