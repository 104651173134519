import React, { Component } from 'react'
import { Row, Col, Dropdown, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChartArea } from '@fortawesome/pro-duotone-svg-icons/faChartArea'
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar'
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons/faUserTie'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers'
import { faUserNinja } from '@fortawesome/pro-duotone-svg-icons/faUserNinja'
import { faUserCog } from '@fortawesome/pro-duotone-svg-icons/faUserCog'
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle'
import { faPowerOff } from '@fortawesome/pro-duotone-svg-icons/faPowerOff'
import { faQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion'
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList'
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser'

import { Redirect } from 'react-router-dom'
import MessagesPopOver from '../../Pages/Messages/MessagesPopOver'
import UpperMenuQuickSearch from './UpperMenuQuickSearch'
import UpperMenuLocales from './UpperMenuLocales'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import Avatar from '../../Elements/Avatar.tsx'

class UpperMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            redirect: false,
        }

        this.handleLogoutClick = this.handleLogoutClick.bind(this)
    }

    handleLogoutClick() {
        this.props.kedo
            .api()
            .get('/logout_cookie')
            .then((response) => {
                localStorage.clear()
                this.setState({ redirect: true })
            })
            .catch((errResponse) => {
                localStorage.clear()
                this.setState({ redirect: true })
            })
    }

    getUserIcon() {
        const kedo = this.props.kedo
        if (kedo.isAdmin()) {
            return faUserNinja
        } else if (kedo.isContractAdmin()) {
            return faUserTie
        } else if (kedo.isEnvironmentAdmin()) {
            return faUserCog
        }

        return faUser
    }

    render() {
        const kedo = this.props.kedo

        if (this.state.redirect) {
            return <Redirect to={'/'} />
        }

        return (
            <Row>
                <Col
                    xs={12}
                    className={'upperMenu d-print-none printUppermenu pr-4'}
                >
                    <ul>
                        <li>
                            {kedo.env() && kedo.env().hasEnviroment() ? (
                                <UpperMenuQuickSearch kedo={kedo} />
                            ) : null}
                        </li>
                        <li>
                            <MessagesPopOver kedo={kedo} />
                        </li>
                        <UpperMenuLocales kedo={kedo} />
                        {kedo.env() &&
                        kedo.env().hasEnviroment() &&
                        (kedo.isAdminOrEnvironmentAdmin() ||
                            kedo.env().hasCredential('export')) &&
                        kedo.env().hasModule('reports') ? (
                            <li>
                                <Button
                                    className={'uppermenu-buttons'}
                                    variant={'light'}
                                    onClick={() =>
                                        this.props.history.push(
                                            '/reports/overview'
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faChartArea} />
                                </Button>
                            </li>
                        ) : null}
                        <li>
                            {kedo.env() && kedo.env().hasEnviroment() ? (
                                <Button
                                    className={'uppermenu-buttons'}
                                    title={kedo.t('Favorites')}
                                    variant={'light'}
                                    onClick={() => this.props.handleSidebar()}
                                >
                                    <FontAwesomeIcon icon={faStar} />
                                </Button>
                            ) : null}
                        </li>
                        <li>
                            <Dropdown onToggle={this.onToggle}>
                                <Dropdown.Toggle variant="light">
                                    {kedo.env() &&
                                    kedo.env().hasEnviroment() ? (
                                        <Avatar
                                            kedo={this.props.kedo}
                                            user={{
                                                userId: kedo.user().getUserId(),
                                                username: kedo
                                                    .user()
                                                    .getUsername(),
                                            }}
                                            props={this.props}
                                            options={{
                                                togglePresence: true,
                                                size: 26,
                                                showPresence: true,
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            kedo={this.props.kedo}
                                            user={{
                                                userId: kedo.user().getUserId(),
                                                username: kedo
                                                    .user()
                                                    .getUsername(),
                                            }}
                                            props={this.props}
                                        />
                                    )}
                                    <span
                                        className={
                                            'ml-1 mr-1 d-none d-sm-inline-block'
                                        }
                                    >
                                        {kedo.user().getUsername()}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.loading ? (
                                        <Dropdown.Item
                                            className={'text-center'}
                                        >
                                            <LoadingDefault />
                                        </Dropdown.Item>
                                    ) : (
                                        <div>
                                            <Dropdown.Item href="/profile">
                                                <FontAwesomeIcon
                                                    icon={faUserCircle}
                                                />{' '}
                                                &nbsp; {kedo.t('My profile')}
                                            </Dropdown.Item>
                                            {kedo
                                                .user()
                                                .isContractAdminUser() ? (
                                                <Dropdown.Item href="/subscriptions">
                                                    <FontAwesomeIcon
                                                        icon={faList}
                                                    />{' '}
                                                    &nbsp;{' '}
                                                    {kedo.t('Subscriptions')}
                                                </Dropdown.Item>
                                            ) : null}
                                            <Dropdown.Item href="/colleagues">
                                                <FontAwesomeIcon
                                                    icon={faUsers}
                                                />{' '}
                                                &nbsp; {kedo.t('Colleagues')}
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item href="/contact">
                                                <FontAwesomeIcon
                                                    icon={faQuestion}
                                                />{' '}
                                                &nbsp; {kedo.t('Contact')}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={this.handleLogoutClick}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPowerOff}
                                                />{' '}
                                                &nbsp; {kedo.t('Logout')}
                                            </Dropdown.Item>
                                        </div>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </Col>
            </Row>
        )
    }
}

export default UpperMenu
