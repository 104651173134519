import React, { Component } from 'react'
import {
    Alert,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
} from 'react-bootstrap'
import SelectList from '../../../Elements/SelectList'
import QueryBuilderCombinator from './QueryCombinator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave'

import LoadingDefault from '../../../Elements/Loading/LoadingDefault'

class QueryBuilderEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: false,
            editTrans: false,
            defdossier: this.props.ddId,
            ddObject: null,
            ddFields: [],
            ddLinks: [],
            combinators: [],
            translations: [],
            loading: false,
        }

        this.onSelectDefDossier = this.onSelectDefDossier.bind(this)
        this.onDeleteCombinator = this.onDeleteCombinator.bind(this)
        this.addQueryCombinator = this.addQueryCombinator.bind(this)
        this.onChangeCombinator = this.onChangeCombinator.bind(this)
        this.onChangeTranslation = this.onChangeTranslation.bind(this)
        this.renderTranslations = this.renderTranslations.bind(this)
        this.renderCombinators = this.renderCombinators.bind(this)
        this.handleShowSuccess = this.handleShowSuccess.bind(this)
        this.save = this.save.bind(this)
    }

    fetchItem() {
        let url = this.props.kedo.api().getDossierQueryEndpoint()
        let id = this.props.match ? this.props.match.params.id : this.props.id
        this.props.kedo
            .api()
            .get(url + '/' + id)
            .then((response) => {
                let combinators = response.data.data.combinators
                    ? response.data.data.combinators
                    : []
                combinators.map((item, index) => {
                    if (!item.uuid) {
                        combinators[index].uuid = this.props.kedo
                            .utils()
                            .uuidv4()
                    }
                })

                this.setState({
                    defdossier: response.data.def_dossier.id,
                    ddObject: response.data.def_dossier,
                    queryObject: response.data,
                    combinators: combinators,
                    translations: response.data.translations
                        ? response.data.translations
                        : [],
                })
            })
    }

    onSelectDefDossier(item) {
        this.setState({ defdossier: item.id }, this.fetchDefDossier)
    }

    selectDefDossier() {
        return (
            <div>
                <SelectList
                    kedo={this.props.kedo}
                    title={this.props.kedo.t('Select entity')}
                    searchName={'search'}
                    url={
                        this.props.kedo.api().getDefDossierEndpoint() +
                        '?environment=' +
                        this.props.kedo.env().getEnvironment().id
                    }
                    onDisplayName={(item) =>
                        item.id +
                        ' ' +
                        this.props.kedo.env().translateItem(item, 'defdossier')
                    }
                    onSelect={this.onSelectDefDossier}
                />
            </div>
        )
    }

    componentDidMount() {
        if (
            (this.props.match && parseInt(this.props.match.params.id) !== 0) ||
            this.props.id > 0
        ) {
            this.fetchItem()
        } else if (this.props.ddId) {
            this.fetchDefDossier()
        }
    }

    fetchDefDossier() {
        let ddUrl = this.props.kedo.api().getDefDossierEndpoint()
        this.props.kedo
            .api()
            .getCached(ddUrl + '/' + this.state.defdossier)
            .then((response) => this.setState({ ddObject: response.data }))
    }

    addQueryCombinator() {
        let combinators = this.state.combinators
        combinators.push({ uuid: this.props.kedo.utils().uuidv4() })
        this.setState({ combinators: combinators })
    }

    onChangeCombinator(index, combinator) {
        let combinators = this.state.combinators
        combinators[index] = combinator
        this.setState({ combinators: combinators })
    }

    onDeleteCombinator(uuid) {
        let combinators = this.state.combinators.filter(
            (item) => item.uuid !== uuid
        )

        this.setState({
            combinators: combinators,
        })
    }

    renderCombinators() {
        return this.state.combinators.map((combinator, comIndex) => (
            <QueryBuilderCombinator
                level={0}
                index={comIndex}
                key={combinator.uuid}
                onDelete={this.onDeleteCombinator}
                onChange={this.onChangeCombinator}
                defdossier={this.state.defdossier}
                ddObject={this.state.ddObject}
                field={combinator}
                kedo={this.props.kedo}
            />
        ))
    }

    handleShowSuccess() {
        this.setState({
            showSuccess: true,
        })

        setTimeout(() => {
            this.setState({
                showSuccess: false,
            })
        }, 2000)
    }

    onChangeTranslation(locale, dashboardName, searchName) {
        let translations = this.state.translations
        let trans = translations.find((item) => item.culture === locale)
        translations = translations.filter((item) => item.culture !== locale)

        if (!trans) {
            trans = {
                culture: locale,
                dashboard_name: dashboardName,
                search_name: searchName,
            }
        } else if (dashboardName !== null) {
            trans.dashboard_name = dashboardName
        } else if (searchName !== null) {
            trans.search_name = searchName
        }
        translations.push(trans)

        this.setState({ translations: translations })
    }

    renderTranslations() {
        let translations = this.state.translations
        if (!translations || translations.length <= 0) {
            translations = []
        }

        let locales = this.props.kedo.env().getEnvironmentLocales()

        if (!locales) {
            return this.props.kedo.t('No languages set')
        }

        return locales.map((locale) => {
            let trans = translations.find(
                (item) => locale.code === item.culture
            )

            if (!trans) {
                trans = {}
            }

            return (
                <Form.Group key={locale.code}>
                    <Form.Label>
                        {this.props.kedo.t('Search name')} ({locale.code})
                    </Form.Label>
                    <Form.Control
                        value={trans ? trans.search_name : ''}
                        type={'text'}
                        onChange={(event) =>
                            this.onChangeTranslation(
                                locale.code,
                                null,
                                event.target.value
                            )
                        }
                    />
                    <Form.Label>
                        {this.props.kedo.t('Dashboard name')} ({locale.code})
                    </Form.Label>
                    <Form.Control
                        value={trans ? trans.dashboard_name : ''}
                        isInvalid={!!this.state.errors[locale]}
                        type={'text'}
                        onChange={(event) =>
                            this.onChangeTranslation(
                                locale.code,
                                event.target.value,
                                null
                            )
                        }
                    />
                </Form.Group>
            )
        })
    }

    save() {
        const kedo = this.props.kedo
        let envId = kedo.env().getEnvironment().id
        let url = kedo.api().getDossierQueryEndpoint()
        let queryId = this.props.match
            ? parseInt(this.props.match.params.id)
            : this.props.id > 0
            ? this.props.id
            : 0
        let item = {
            environment: envId,
            defDossier: this.state.defdossier,
            data: { combinators: this.state.combinators },
            translations: this.state.translations,
        }
        this.setState({ saving: true })
        if (queryId !== 0) {
            kedo.api()
                .patch(url + '/' + queryId, item)
                .then((response) => {
                    kedo.env().reloadEnvironment(envId, kedo.api(), () => {
                        this.setState({ saving: false }, this.handleShowSuccess)
                    })
                })
        } else if (queryId === 0) {
            kedo.api()
                .post(url, item)
                .then((response) => {
                    kedo.env().reloadEnvironment(envId, kedo.api(), () => {
                        if (this.props.onSuccess) {
                            this.handleShowSuccess()
                            this.props.onSuccess(
                                response.data.id,
                                this.props.onClose
                            )
                        } else {
                            window.location =
                                '/querybuilder/' + response.data.id
                        }
                    })
                })
        }
    }

    renderInfoModal() {
        const kedo = this.props.kedo

        return (
            <Modal
                show={true}
                size={'lg'}
                onHide={() => this.setState({ showInfo: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {kedo.t('Info')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Row>
                        <Col sm={12}>
                            <table className={'table table-striped'}>
                                <tbody>
                                    <tr>
                                        <th>{kedo.t('Id')}</th>
                                        <td>{this.state.queryObject.id}</td>
                                    </tr>
                                    <tr>
                                        <th>{kedo.t('Uuid')}</th>
                                        <td>{this.state.queryObject.uuid}</td>
                                    </tr>
                                    <tr>
                                        <th>{kedo.t('Created by')}</th>
                                        <td>
                                            {this.state.queryObject.created_by
                                                ? this.state.queryObject
                                                      .created_by.username
                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{kedo.t('Created at')}</th>
                                        <td>
                                            {this.state.queryObject.created_at
                                                ? kedo
                                                      .utils()
                                                      .dateFormat(
                                                          this.state.queryObject
                                                              .created_at
                                                      )
                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{kedo.t('Modified by')}</th>
                                        <td>
                                            {this.state.queryObject.modified_by
                                                ? this.state.queryObject
                                                      .modified_by.username
                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{kedo.t('Modified at')}</th>
                                        <td>
                                            {this.state.queryObject.modified_at
                                                ? kedo
                                                      .utils()
                                                      .dateFormat(
                                                          this.state.queryObject
                                                              .modified_at
                                                      )
                                                : null}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.setState({ showInfo: false })}
                        variant="secondary"
                    >
                        {kedo.t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderTranslationsModal() {
        const kedo = this.props.kedo
        return (
            <Modal
                show={true}
                size={'lg'}
                onHide={() => this.setState({ editTrans: false })}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {kedo.t('Edit translations')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'pillsModalBody'}>
                    <Tab.Container defaultActiveKey="translations">
                        <Row>
                            <Col sm={3} className={'pillsSidebar'}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="translations">
                                            {kedo.t('Translations')}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9} className={'pillsContent'}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="translations">
                                        <h3>{kedo.t('Translations')}</h3>
                                        <Form>{this.renderTranslations()}</Form>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => this.setState({ editTrans: false })}
                        variant="secondary"
                    >
                        {kedo.t('Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const kedo = this.props.kedo

        if (this.state.loading) {
            return <LoadingDefault />
        }

        return (
            <div id="kedo-querybuilder" className={'mainContainer'}>
                {this.state.editTrans === true
                    ? this.renderTranslationsModal()
                    : null}
                {this.state.showInfo === true ? this.renderInfoModal() : null}
                <Row>
                    <Col md={12}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a
                                        onClick={() =>
                                            this.props.history.push('/')
                                        }
                                        href="#"
                                    >
                                        {kedo.t('Dashboard')}
                                    </a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a
                                        onClick={() =>
                                            this.props.history.push(
                                                '/querybuilder'
                                            )
                                        }
                                        href="#"
                                    >
                                        {kedo.t('Queries')}
                                    </a>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                >
                                    {kedo.t('Edit')}
                                </li>
                            </ol>
                        </nav>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <h1>
                            {kedo.t('QueryBuilder editor')}
                            {this.state.defdossier ? (
                                <ButtonGroup className={'float-right'}>
                                    <Button onClick={this.save}>
                                        <FontAwesomeIcon icon={faSave} />{' '}
                                        {this.state.saving ? (
                                            <LoadingDefault as={'span'} />
                                        ) : null}
                                    </Button>
                                </ButtonGroup>
                            ) : null}
                        </h1>
                        {this.state.showSuccess ? (
                            <Alert
                                className={`k-alert alert alert-success alert-shown`}
                                variant={'success'}
                            >
                                <FontAwesomeIcon icon={faCheck} />{' '}
                                {kedo.t('Item has been saved')}
                            </Alert>
                        ) : null}
                        {!this.state.defdossier
                            ? this.selectDefDossier()
                            : null}
                        {this.state.defdossier ? (
                            <div className="card border-primary mb-3">
                                <div className="card-header bg-dark">
                                    <h4>
                                        {this.state.ddObject
                                            ? this.state.ddObject.name_plural
                                            : ''}{' '}
                                        {this.renderButtonGroupBar()}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    {this.state.defdossier
                                        ? this.renderCombinators()
                                        : null}
                                </div>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </div>
        )
    }

    renderButtonGroupBar() {
        return (
            <ButtonToolbar
                className={'float-right'}
                aria-label="Toolbar with button groups"
            >
                <ButtonGroup size={'sm'} className="me-2">
                    <Button
                        title={this.props.kedo.t('Settings')}
                        size={'sm'}
                        onClick={() => this.setState({ editTrans: true })}
                    >
                        <FontAwesomeIcon icon={faCog} />
                    </Button>
                    {this.state.queryObject ? (
                        <Button
                            size={'sm'}
                            onClick={() => this.setState({ showInfo: true })}
                        >
                            <FontAwesomeIcon icon={faInfo} />
                        </Button>
                    ) : null}
                    <Button
                        title={'add query combinator'}
                        size={'sm'}
                        onClick={this.addQueryCombinator}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        )
    }
}

export default QueryBuilderEditor
