import React, { PureComponent } from 'react'
import {
    Button,
    ButtonGroup,
    Row,
    Form,
    Col,
    DropdownButton,
    Dropdown,
    Modal,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap'
import ContentDossierStatistics from './MyPage/ContentDossierStatistics'
import UserStatistics from './MyPage/UserStatistics'
import TemplateInstall from '../Template/TemplateInstall'
import ExpiredModal from './Upgrade/ExpiredModal'
import UserMostActiveStatistics from './MyPage/UserMostActiveStatistics'
import Block from './MyPage/Block'
import BlockCountList from './MyPage/BlockCountList'
import BlockSumFields from './MyPage/BlockSumFields'
import BlockCountLink from './MyPage/BlockCountLink'
import BlockLazy from './MyPage/BlockLazy'
import BlockListPercentageOptionMatching from './MyPage/BlockListPercentageOptionMatching'
import { WidthProvider, Responsive } from 'react-grid-layout'
import LoadingDefault from '../Elements/Loading/LoadingDefault'
import LoadingPage from '../Elements/Loading/LoadingPage'
import BlockDateStatistics from './MyPage/BlockDateStatistics'
import BlockContainer from './MyPage/BlockContainer'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-duotone-svg-icons/faPrint'
import { faTimer } from '@fortawesome/pro-duotone-svg-icons/faTimer'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faExpand } from '@fortawesome/pro-duotone-svg-icons/faExpand'
import { faSquareDashedCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faSquareDashedCirclePlus'
import { faCalendarRange } from '@fortawesome/pro-duotone-svg-icons/faCalendarRange'
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash'

import BlockGeomap from './MyPage/BlockGeomap'
import BlockForm from '../Admin/Pages/Blocks/BlockForm'
import BlockFunnel from './MyPage/BlockFunnel'
import Toggle from 'react-toggle'
import { faEdit } from '@fortawesome/pro-duotone-svg-icons/faEdit'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

class MyPage extends PureComponent {
    constructor(props) {
        super(props)
        this.selector = React.createRef()
        this.otherSelector = React.createRef()
        this.observer = null
        this.state = {
            makeReport: false,
            dateFormat: null,
            refreshRate: null,
            expandBlock: null,
            refreshInt: null,
            savingPos: false,
            pos_default: false,
            grayedDateBlocks: '',
            loading: false,
            toggleDrag: false,
            subscriptionExpired: null,
            blocks: [],
            hasMore: true,
            hiddenBlocks: [],
            layout: [],
            isActivated: false,
            presentation: false,
            newBlock: false,
        }

        this.onLayoutChange = this.onLayoutChange.bind(this)
        this.submitPositions = this.submitPositions.bind(this)
        this.updateData = this.updateData.bind(this)
        this.changeDateBlocks = this.changeDateBlocks.bind(this)
        this.changeRefreshRate = this.changeRefreshRate.bind(this)
        this.hideBlock = this.hideBlock.bind(this)
        this.saveAndRefresh = this.saveAndRefresh.bind(this)
        this.getBlockHeader = this.getBlockHeader.bind(this)
        this.revertHiddenBlocks = this.revertHiddenBlocks.bind(this)
    }

    handleFullScreenChange = () => {
        if (!document.fullscreenElement && this.isActivated) {
            this.moodPresentation()
            this.presentfullScreen()
        }
    }

    presentfullScreen() {
        if (this.isActivated) {
            document.documentElement.requestFullscreen()
        } else {
            document.exitFullscreen()
        }
    }

    saveAndRefresh(blockId) {
        let api = this.props.kedo.api()
        this.setState({ blockLoading: blockId })
        api.get(api.getMyPageBlockEndpoint(blockId)).then((blockResponse) => {
            let blocks = this.state.blocks
            for (let i = 0; i < blocks.length; i++) {
                if (blocks[i].id === blockId) {
                    blocks[i].settings = blockResponse.data.settings
                }
            }

            this.setState({ blocks: blocks, blockLoading: null }, () =>
                this['blockRef' + blockId].current.fetchData()
            )
        })
    }

    revertHiddenBlocks() {
        this.setState({ loading: true })
        let blocks = [...this.state.blocks]
        let layout = [...this.state.layout]

        this.state.hiddenBlocks.forEach((item) => {
            blocks.push(item.blocks)
            layout.push(item.layout)
        })

        this.setState({
            loading: false,
            layout: layout,
            blocks: blocks,
            hiddenBlocks: [],
        })
    }

    hideBlock(uuid) {
        this.setState({ loading: true })
        let blocks = this.state.blocks.filter((item) => item.uuid !== uuid)
        let layout = this.state.layout.filter((item) => item.i !== uuid)
        let hidden = this.state.hiddenBlocks

        hidden.push({
            layout: this.state.layout.find((item) => item.i === uuid),
            blocks: this.state.blocks.find((item) => item.uuid === uuid),
        })

        this.setState({
            loading: false,
            layout: layout,
            blocks: blocks,
            hiddenBlocks: hidden,
        })
    }

    fetchMyPageBlocks() {
        let api = this.props.kedo.api()
        let environment = this.props.kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        let params = {
            params: {
                dashboard: true,
                environment: environment.id,
                default: this.state.pos_default,
            },
        }

        this.setState({ loading: true })
        api.get(api.getMyPageBlockEndpoint(), params).then((response) => {
            let layout = []

            for (let i = 0; i < response.data.positions.length; i++) {
                layout.push({
                    x: response.data.positions[i].x,
                    y: response.data.positions[i].y,
                    w:
                        response.data.positions[i].w <= 0
                            ? 5
                            : response.data.positions[i].w,
                    h:
                        response.data.positions[i].h <= 0
                            ? 5
                            : response.data.positions[i].h,
                    i: response.data.positions[i].uuid,
                    minW: 5,
                    minH: 5,
                })
            }

            //Add default for new blocks
            for (let b = 0; b < response.data.results.length; b++) {
                this['blockRef' + response.data.results[b].id] =
                    React.createRef()
                this['divBlockRef' + response.data.results[b].id] =
                    React.createRef()

                let isExisting = layout.find(
                    (lItem) => lItem.i === response.data.results[b].uuid
                )
                if (!isExisting) {
                    layout.push({
                        x: (b * 6) % 12,
                        y: 0,
                        w: 6,
                        h: 10,
                        i: response.data.results[b].uuid,
                        minW: 5,
                        minH: 5,
                    })
                }
            }

            this.setState({
                loading: false,
                layout,
                blocks: response.data.results,
                pagerBlocks: response.data.pager,
            })
        })
    }

    checkContract() {
        let api = this.props.kedo.api()
        api.get(api.getOwnActiveSubscriptionEndpoint()).then((response) => {
            if (!response.data.subscription && response.data.expired) {
                this.setState({ subscriptionExpired: true })
            }
        })
    }

    renderExpired() {
        return <ExpiredModal kedo={this.props.kedo} />
    }

    componentDidMount() {
        if (this.props.kedo.isContractAdmin()) {
            this.checkContract()
        }

        this.fetchMyPageBlocks()
        this.props.kedo.title().set(this.props.kedo.t('Dashboard'))
        document.addEventListener(
            'fullscreenchange',
            this.handleFullScreenChange
        )
    }

    getBlockHeader(block, dossier) {
        if (!block || (dossier && dossier.id)) {
            return ''
        }

        if (block.def_dossier_id) {
            return (
                <h4>
                    <a href={`/defdossier/${block.def_dossier_id}`}>
                        {this.props.kedo
                            .env()
                            .translateItem(block, 'mypageblock')}
                    </a>
                </h4>
            )
        }

        return (
            <h4>{this.props.kedo.env().translateItem(block, 'mypageblock')}</h4>
        )
    }

    renderMyPageBlock(item, kedo, enlarged) {
        let blockProps = {
            kedo: kedo,
            block: item,
            dashboard: true,
            onClose: this.hideBlock,
            ref: enlarged === false ? this['blockRef' + item.id] : null,
            onSuccess: this.saveAndRefresh,
            getBlockHeader: this.getBlockHeader,
            onMax: (uuid) => this.setState({ expandBlock: uuid }),
        }

        switch (item.type) {
            case 'block_container':
                return <BlockContainer {...blockProps} />
            case 'dashboard':
                return <Block {...blockProps} />
            case 'count_list_options':
                return <BlockCountList {...blockProps} />
            case 'list_option_percentage_conditions':
                return <BlockListPercentageOptionMatching {...blockProps} />
            case 'count_link_options':
                return <BlockCountLink {...blockProps} />
            case 'sum_fields':
                return <BlockSumFields {...blockProps} />
            case 'new_dossier_statistics':
                return <ContentDossierStatistics {...blockProps} />
            case 'active_users':
                return <UserMostActiveStatistics {...blockProps} />
            case 'online_users':
                return <UserStatistics {...blockProps} />
            case 'date_statistics':
                return <BlockDateStatistics {...blockProps} />
            case 'geomap':
                return <BlockGeomap {...blockProps} />
            case 'funnel':
                return <BlockFunnel {...blockProps} />
        }

        return ''
    }

    onLayoutChange(layout) {
        this.setState(
            {
                layout: layout,
            },
            this.state.toggleDrag ? this.submitPositions : null
        )
    }

    submitPositions() {
        let api = this.props.kedo.api()
        let envId = this.props.kedo.env().getEnvironment().id
        let payload = []
        for (let i = 0; i < this.state.layout.length; i++) {
            payload.push({
                block: this.state.layout[i].i,
                xPos: this.state.layout[i].x,
                yPos: this.state.layout[i].y,
                blockWidth: this.state.layout[i].w,
                blockHeight: this.state.layout[i].h,
            })
        }

        let dataPayload = { blocks: payload }

        if (this.state.pos_default === true) {
            dataPayload.default = true
        }

        this.setState({ savingPos: true })
        api.patch('/mypage/' + envId + '/position', dataPayload).then(
            (response) => this.setState({ savingPos: false })
        )
    }

    setAndGetDefaultPositions() {
        let api = this.props.kedo.api()
        let envId = this.props.kedo.env().getEnvironment().id

        this.setState(
            {
                loading: true,
            },
            () => {
                let params = { params: {} }
                if (this.state.pos_default) {
                    params.params.default = true
                }

                api.get('/mypage/' + envId + '/position', params).then(
                    (response) => {
                        let layout = []

                        for (let i = 0; i < response.data.length; i++) {
                            layout.push({
                                x: response.data[i].x,
                                y: response.data[i].y,
                                w:
                                    response.data[i].w <= 0
                                        ? 5
                                        : response.data[i].w,
                                h:
                                    response.data[i].h <= 0
                                        ? 5
                                        : response.data[i].h,
                                i: response.data[i].uuid,
                                minW: 5,
                                minH: 5,
                            })
                        }

                        this.setState({ loading: false, layout: layout })
                    }
                )
            }
        )
    }

    updateData(dataName, dataVal) {
        this.setState({ [dataName]: dataVal })
    }

    changeRefreshRate(eventKey) {
        if (this.state.refreshInt) {
            clearInterval(this.state.refreshInt)
        }

        let defRefRate = 30 * 60 * 1000

        if (eventKey === 'minute') {
            defRefRate = 60000
        } else if (eventKey === 'five_minute') {
            defRefRate = 5 * 60 * 1000
        } else if (eventKey === 'fifteen_minute') {
            defRefRate = 15 * 60 * 1000
        } else if (eventKey === 'thirty_minute') {
            defRefRate = 30 * 60 * 1000
        }

        let refreshInt = setInterval(() => {
            this.state.blocks.map((blockItem) => {
                let blockRefId = 'blockRef' + blockItem.id
                if (
                    this[blockRefId] &&
                    this[blockRefId].current &&
                    this[blockRefId].current &&
                    this[blockRefId].current.fetchData
                ) {
                    /**
                     * Check if an element is in viewport
                     *
                     * @param {number} [offset]
                     * @returns {boolean}
                     */
                    let eOffset = 0
                    if (this[blockRefId].current.getParentRef) {
                        const eTop = this[blockRefId].current
                            .getParentRef()
                            .current.getBoundingClientRect().top
                        if (
                            eTop + eOffset >= 0 &&
                            eTop - eOffset <= window.innerHeight
                        ) {
                            this[blockRefId].current.fetchData(false)
                        }
                    }
                }
            })
        }, defRefRate)
        this.setState({
            refreshRate: eventKey,
            refreshInt: refreshInt,
        })
    }

    changeDateBlocks(eventKey) {
        this.setState({ dateFormat: eventKey }, () => {
            this.state.blocks.map((blockItem) => {
                let blockRefId = 'blockRef' + blockItem.id
                if (
                    this[blockRefId] &&
                    this[blockRefId].current &&
                    this[blockRefId].current.setFilterAndFetchData
                ) {
                    this[blockRefId].current.setFilterAndFetchData(
                        'dateFormat',
                        eventKey
                    )
                }
            })
        })
    }

    moodPresentation() {
        if (typeof this.state.openSidebar === 'undefined') {
            this.presentation = false
        } else {
            this.presentation = !this.state.openSidebar
        }

        this.isActivated = !this.state.isActivated
        this.setState({
            openSidebar: this.presentation,
            isActivated: this.isActivated,
        })
        this.props.onCloseSidebar(this.presentation)
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshInt)
    }

    renderExpandedBlock(uuid) {
        let block = this.state.blocks.find((block) => block.uuid === uuid)
        const kedo = this.props.kedo
        let mHeight = window.innerHeight - 100

        return (
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => this.setState({ expandBlock: null })}
            >
                <Modal.Body>
                    <ReactToPrint content={() => this.otherSelector}>
                        <ButtonGroup className={'float-right mr-2'}>
                            <PrintContextConsumer>
                                {({ handlePrint }) => (
                                    <Button size={'sm'} onClick={handlePrint}>
                                        <FontAwesomeIcon icon={faPrint} />
                                    </Button>
                                )}
                            </PrintContextConsumer>
                            <Button
                                size={'sm'}
                                onClick={() =>
                                    this.setState({ expandBlock: null })
                                }
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </ButtonGroup>

                        <div
                            style={{ height: mHeight, overflowY: 'scroll' }}
                            ref={(el) => (this.otherSelector = el)}
                        >
                            <style type="text/css" media="print">
                                {
                                    '@\
                            @media print {\
                            @page { size: landscape; }\
                            }\
                            page { size: landscape; }\
                        '
                                }
                            </style>

                            {this.state.blockLoading === block.id ? (
                                <center>
                                    <LoadingDefault />
                                </center>
                            ) : (
                                this.renderMyPageBlock(block, kedo, true)
                            )}
                        </div>
                    </ReactToPrint>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        const kedo = this.props.kedo
        let hasTemplate =
            kedo.isAdminOrEnvironmentAdmin() && kedo.env().hasTemplate()

        if (this.state.loading) {
            return (
                <div className="mypage">
                    <LoadingPage />
                </div>
            )
        }

        return (
            <div className="mypage">
                {this.state.expandBlock
                    ? this.renderExpandedBlock(this.state.expandBlock)
                    : null}
                {this.state.newBlock ? (
                    <BlockForm
                        variant={'new'}
                        kedo={kedo}
                        onSuccess={() => {
                            this.fetchMyPageBlocks()
                            this.setState({ newBlock: false })
                        }}
                        onClose={() => this.setState({ newBlock: false })}
                    />
                ) : null}
                <ReactToPrint content={() => this.selector}>
                    <Row>
                        <Col xs={12} sm={12}>
                            <ButtonGroup className={'float-right'}>
                                {kedo.isAdminOrEnvironmentAdmin() ? (
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                {kedo.t('Add Block')}
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            size={'md'}
                                            onClick={() =>
                                                this.setState({
                                                    newBlock: true,
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faSquareDashedCirclePlus}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                ) : null}

                                <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip>
                                            {kedo.t(
                                                this.state.isActivated
                                                    ? 'Minimize'
                                                    : 'Maximize'
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <Button
                                        size={'md'}
                                        onClick={() => {
                                            this.moodPresentation()
                                            this.presentfullScreen()
                                        }}
                                    >
                                        {' '}
                                        <FontAwesomeIcon icon={faExpand} />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip>
                                            {kedo.t(
                                                this.state.refreshRate
                                                    ? this.state.refreshRate
                                                    : 'Refresh'
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <DropdownButton
                                        as={ButtonGroup}
                                        menuAlign="right"
                                        title={
                                            <FontAwesomeIcon icon={faTimer} />
                                        }
                                        id={`b-dash-rr`}
                                        size={'md'}
                                    >
                                        <Dropdown.Item
                                            eventKey={'thirty_minute'}
                                            onSelect={this.changeRefreshRate}
                                        >
                                            {'30 ' + kedo.t('minutes')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={'fifteen_minute'}
                                            onSelect={this.changeRefreshRate}
                                        >
                                            {'15 ' + kedo.t('minutes')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={'five_minute'}
                                            onSelect={this.changeRefreshRate}
                                        >
                                            {'5 ' + kedo.t('minutes')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={'minute'}
                                            onSelect={this.changeRefreshRate}
                                        >
                                            {'1 ' + kedo.t('minute')}
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip>
                                            {kedo.t(
                                                this.state.dateFormat
                                                    ? this.state.dateFormat
                                                    : 'Choose an option'
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <DropdownButton
                                        as={ButtonGroup}
                                        menuAlign="right"
                                        title={
                                            <FontAwesomeIcon
                                                icon={faCalendarRange}
                                            />
                                        }
                                        id={`b-dash-f`}
                                        size={'md'}
                                        onToggle={(isOpen) => {
                                            if (isOpen) {
                                                this.setState({
                                                    grayedDateBlocks:
                                                        ' kedo-block-grayed-dates',
                                                })
                                            } else {
                                                this.setState({
                                                    grayedDateBlocks: '',
                                                })
                                            }
                                        }}
                                    >
                                        <Dropdown.Item
                                            eventKey={'year_month'}
                                            onSelect={this.changeDateBlocks}
                                        >
                                            {kedo.t('year_month')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={'quarter'}
                                            onSelect={this.changeDateBlocks}
                                        >
                                            {kedo.t('quarter')}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={'year'}
                                            onSelect={this.changeDateBlocks}
                                        >
                                            {kedo.t('year')}
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </OverlayTrigger>
                                <PrintContextConsumer>
                                    {({ handlePrint }) => (
                                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                                <Tooltip>
                                                    {kedo.t('Print')}
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                size={'md'}
                                                onClick={handlePrint}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPrint}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                    )}
                                </PrintContextConsumer>
                                {this.state.hiddenBlocks.length > 0 ? (
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                {kedo.t('Hidden')}
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            size={'md'}
                                            onClick={() =>
                                                this.revertHiddenBlocks()
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faEyeSlash}
                                            />
                                        </Button>
                                    </OverlayTrigger>
                                ) : null}
                                <div className={'btn-group mr-1'}>
                                    <Toggle
                                        checked={this.state.toggleDrag}
                                        className="toggle-dashboard-view"
                                        title={kedo.t('Toggle drag')}
                                        aria-label={kedo.t('Toggle drag')}
                                        icons={{
                                            checked: (
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                />
                                            ),
                                            unchecked: null,
                                        }}
                                        onChange={() => {
                                            this.setState(
                                                {
                                                    toggleDrag:
                                                        !this.state.toggleDrag,
                                                },
                                                () => {
                                                    this.state.pos_default
                                                        ? this.setAndGetDefaultPositions()
                                                        : null
                                                }
                                            )
                                        }}
                                    />
                                </div>

                                {kedo.isAdminOrEnvironmentAdmin() ? (
                                    <Form.Control
                                        value={
                                            this.state.pos_default
                                                ? 'default_view'
                                                : 'my_view'
                                        }
                                        size="sm"
                                        as="select"
                                        onChange={(e) => {
                                            this.setState(
                                                {
                                                    pos_default:
                                                        e.target.value ===
                                                        'default_view',
                                                },
                                                this.setAndGetDefaultPositions
                                            )
                                        }}
                                    >
                                        <option value="default_view">
                                            {kedo.t('Default view')}
                                        </option>
                                        <option value={'my_view'}>
                                            {kedo.t('My view')}
                                        </option>
                                    </Form.Control>
                                ) : null}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {this.state.subscriptionExpired
                        ? this.renderExpired()
                        : null}
                    {hasTemplate ? (
                        <TemplateInstall
                            kedo={kedo}
                            templateId={
                                kedo.env().getEnvironment().apply_template
                            }
                        />
                    ) : null}
                    {kedo.isAdminOrEnvironmentAdmin() &&
                    this.state.blocks.length > 0 ? (
                        <hr className={'statistics'} />
                    ) : null}
                    <ResponsiveReactGridLayout
                        ref={(el) => (this.selector = el)}
                        className={`layout ${this.state.grayedDateBlocks}`}
                        isDraggable={this.state.toggleDrag}
                        isResizable={this.state.toggleDrag}
                        rowHeight={25}
                        breakpoints={{
                            lg: 1200,
                            md: 996,
                            sm: 768,
                            xs: 480,
                            xxs: 0,
                        }}
                        cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 4 }}
                        onResizeStop={() => this.setState({ moved: true })}
                        onDragStop={() => this.setState({ moved: true })}
                        onLayoutChange={this.onLayoutChange}
                        layout={this.state.layout}
                    >
                        {this.state.blocks.length > 0
                            ? this.state.blocks.map((item) => {
                                  let showYScroll =
                                      item.settings?.show_y_scroll === 'yes'
                                          ? true
                                          : false
                                  let isDateFilteredBlock =
                                      (item.settings?.use_creation_date ||
                                          item.settings?.date_field) &&
                                      item.type !== 'date_statistics'

                                  return (
                                      <div
                                          key={item.uuid}
                                          className={`kedo-block d-print-block ${
                                              showYScroll === true
                                                  ? 'kedo-block-show-y'
                                                  : ''
                                          } ${
                                              isDateFilteredBlock
                                                  ? 'kedo-date-block'
                                                  : ''
                                          }`}
                                          data-grid={this.state.layout.find(
                                              (layoutItem) =>
                                                  layoutItem.i === item.uuid
                                          )}
                                      >
                                          <BlockLazy>
                                              {this.state.blockLoading ===
                                              item.id ? (
                                                  <center>
                                                      <LoadingDefault />
                                                  </center>
                                              ) : (
                                                  this.renderMyPageBlock(
                                                      item,
                                                      kedo,
                                                      false
                                                  )
                                              )}
                                          </BlockLazy>
                                      </div>
                                  )
                              })
                            : null}
                    </ResponsiveReactGridLayout>
                </ReactToPrint>
            </div>
        )
    }
}

export default MyPage
