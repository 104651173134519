import React, { Suspense } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import { slide as Menu } from 'react-burger-menu'
import MenuBars from '../../images/bars.svg'
import MainMenuItems from './MainMenu/MainMenuItems'
import UpperMenu from './UpperMenu/UpperMenu'
import MyPage from '../Pages/MyPage'
import UpperMenuContact from './UpperMenu/UpperMenuContact'
import LoadingPage from '../Elements/Loading/LoadingPage'
import ErrorBoundary from '../Elements/ErrorBoundary'

import { faHome } from '@fortawesome/pro-duotone-svg-icons/faHome'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Reports from '../Admin/Pages/Reports/Reports'
import Favorites from './Favorites'
const ToewijzingIndex = React.lazy(() =>
    import('../../feature/Module/JwWmo/ToewijzingIndex')
)
const DetailContentDossier = React.lazy(() =>
    import('../../feature/ContentDossier/Detail/ContentDossier.tsx')
)
const CreateContentDossier = React.lazy(() =>
    import('../../feature/ContentDossier/Create/CreateContentDossier.tsx')
)

const DefDossierIndex = React.lazy(() =>
    import('../Pages/DefDossier/DefDossierIndex')
)

const MessageOverview = React.lazy(() =>
    import('../Pages/Messages/MessagesOverview')
)

const UserProfile = React.lazy(() => import('../Admin/Pages/User/UserProfile'))
const Colleagues = React.lazy(() => import('../Pages/Colleagues'))
const UserIndex = React.lazy(() => import('../Admin/Pages/User/UserIndex'))
const Files = React.lazy(() => import('../Admin/Pages/Files/Files'))
const ReportsOverview = React.lazy(() =>
    import('../Admin/Pages/Reports/ReportsOverview')
)

//EnvironmentAdmin
const DefDossierConditions = React.lazy(() =>
    import('../Admin/Pages/DefDossier/DefDossierConditions')
)
const QueryBuilderIndex = React.lazy(() =>
    import('../Admin/Pages/QueryBuilder/QueryBuilderIndex')
)
const QueryBuilderEditor = React.lazy(() =>
    import('../Admin/Pages/QueryBuilder/QueryBuilderEditor')
)
const EnvironmentEdit = React.lazy(() =>
    import('../Admin/Pages/Environment/EnvironmentEdit')
)
const DefDossierEdit = React.lazy(() =>
    import('../Admin/Pages/DefDossier/DefDossierEdit')
)
const BlocksIndex = React.lazy(() =>
    import('../Admin/Pages/Blocks/BlocksIndex')
)
const BlockDetail = React.lazy(() =>
    import('../Admin/Pages/Blocks/BlockDetail')
)
const RolesIndex = React.lazy(() => import('../Admin/Pages/Roles/RolesIndex'))
const RoleDetail = React.lazy(() => import('../Admin/Pages/Roles/RoleDetail'))
const ModulesIndex = React.lazy(() =>
    import('../Admin/Pages/Modules/ModulesIndex')
)
const FieldEditor = React.lazy(() =>
    import('../Admin/Pages/DefDossier/FieldEditor')
)
const KedocxIndex = React.lazy(() =>
    import('../Admin/Pages/Kedocx/KedocxIndex')
)
const KedocxEditor = React.lazy(() =>
    import('../Admin/Pages/Kedocx/KedocxEditor')
)
const TemplateOverview = React.lazy(() =>
    import('../Admin/Pages/Templates/TemplateOverview')
)
const PermissionIndex = React.lazy(() =>
    import('../Admin/Pages/Permissions/PermissionIndex')
)
const Languages = React.lazy(() => import('../Admin/Pages/Languages/Languages'))
const LogIndex = React.lazy(() => import('../Admin/Pages/Log/LogIndex'))
const TemplateForm = React.lazy(() =>
    import('../Admin/Pages/Templates/TemplateForm')
)
const SecurityIndex = React.lazy(() =>
    import('../Admin/Pages/Security/SecurityIndex')
)
const Layout = React.lazy(() => import('../Admin/Pages/Layout/Layout'))
const EventsIndex = React.lazy(() =>
    import('../Admin/Pages/Events/EventsIndex')
)
const Upgrade = React.lazy(() => import('../Pages/Upgrade/Upgrade'))
const WebhookIndex = React.lazy(() =>
    import('../Admin/Pages/Webhooks/WebhookIndex')
)
const DataStructureIndex = React.lazy(() =>
    import('../Admin/Pages/DataStructures/DataStructuresIndex')
)
const DataStructureEdit = React.lazy(() =>
    import('../Admin/Pages/DataStructures/DataStructuresEdit')
)
const UserActivityIndex = React.lazy(() =>
    import('../Admin/Pages/User/UserActivityIndex')
)

//Other
const ImportData = React.lazy(() => import('../Pages/Import/ImportData'))
const MySubscriptions = React.lazy(() =>
    import('../Pages/MySubscriptions/MySubscriptions')
)
const MyOrder = React.lazy(() => import('../Pages/MySubscriptions/MyOrder'))

class DefaultPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            menuItems: [],
            fetchedTemplate: false,
            id: this.props.match.params.id,
            menuOpen:
                window.innerWidth > 576
                    ? this.props.kedo.env().getMenuStatus()
                    : false,
            menuWidth: '16.67%',
            sidebarOpen: false,
            navPresent: true,
        }

        this.handleStateChange = this.handleStateChange.bind(this)
        this.closeMenu = this.closeMenu.bind(this)
        this.setMenuWidth = this.setMenuWidth.bind(this)
        this.handleSidebar = this.handleSidebar.bind(this)
        this.openSidebar = this.openSidebar.bind(this)
        this.closeSidebar = this.closeSidebar.bind(this)
        this.moodPresentation = this.moodPresentation.bind(this)
    }

    moodPresentation(statusPresent) {
        this.handleStateChange(statusPresent)
        this.setState({
            menuOpen: statusPresent,
            navPresent: !this.state.navPresent,
        })
    }

    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen })

        if (!this.state.menuOpen) {
            this.props.kedo.env().setMenuStatus()
        }

        if (this.state.menuOpen) {
            localStorage.removeItem('menu')
            this.props.kedo.env().clearMenuStatus()
        }
    }

    closeMenu() {
        this.setState({ menuOpen: false })
    }

    handleSidebar(state) {
        this.setState({ sidebarOpen: state.isOpen })
    }

    openSidebar() {
        this.setState({ sidebarOpen: true })
    }

    closeSidebar() {
        this.setState({ sidebarOpen: false })
    }

    setMenuWidth() {
        if (window.innerWidth > 576) {
            this.setState({
                menuWidth: '16,67%',
            })
        } else {
            this.setState({
                menuWidth: '100%',
            })
        }
    }

    getMenu() {
        if (!this.props.kedo.env().getEnvironment()) {
            return (
                <Link
                    className={'noMenu'}
                    onClick={() => {
                        localStorage.removeItem('environment')
                        this.props.kedo.env().clearInfo()
                    }}
                    to={'/environment'}
                    style={{ textDecoration: 'none' }}
                >
                    <Button
                        className={'menuBarsButton noMenu'}
                        style={{
                            left: '0px',
                            top: '0px',
                            marginTop: '20px',
                            marginLeft: '34px',
                        }}
                    >
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                </Link>
            )
        }

        return (
            <div>
                {this.state.navPresent && (
                    <Menu
                        isOpen={this.state.menuOpen}
                        onStateChange={(state) => this.handleStateChange(state)}
                        pageWrapId={'page-wrap'}
                        width={this.state.menuWidth}
                        burgerButtonClassName={'menuBarsButton'}
                        customBurgerIcon={
                            <img src={MenuBars} alt={'Open menu'} />
                        }
                        noOverlay
                    >
                        {this.props.kedo.env().getEnvironment() ? (
                            <div
                                className={'d-flex align-items-end flex-column'}
                                style={{ height: '100%' }}
                            >
                                <div className="mainMenuFlex mb-auto">
                                    <MainMenuItems
                                        history={this.props.history}
                                        closing={this.closeMenu}
                                        kedo={this.props.kedo}
                                    />
                                </div>
                                <div className="mainMenuFlex releaseInfo">
                                    <small>
                                        {/* eslint-disable-next-line no-undef */}
                                        Lanthanum Yellow {appVersion}
                                    </small>
                                </div>
                            </div>
                        ) : (
                            <Link
                                onClick={() => {
                                    localStorage.removeItem('environment')
                                    this.props.kedo.env().clearInfo()
                                }}
                                to={'/environment'}
                                style={{ textDecoration: 'none' }}
                            >
                                <Alert
                                    variant={'light'}
                                    style={{ margin: '50px 20px' }}
                                >
                                    {this.props.kedo.t('Select environment')}
                                </Alert>
                            </Link>
                        )}
                    </Menu>
                )}
            </div>
        )
    }

    getResponsiveContainer() {
        if (this.state.menuOpen) {
            return (
                <Col md={{ span: 10, offset: 2 }} className={'menu-col'}>
                    {this.getMain()}
                </Col>
            )
        } else {
            return (
                <Col md={12} className={'menu-col'}>
                    {this.getMain()}
                </Col>
            )
        }
    }

    getMain() {
        const kedo = this.props.kedo

        return (
            <main id="page-wrap">
                {this.state.navPresent && (
                    <UpperMenu
                        kedo={kedo}
                        handleLogout={this.props.handleLogout}
                        handleSidebar={this.openSidebar}
                        history={this.props.history}
                    />
                )}
                <Row>
                    <Col xs={12} style={{ padding: 0 }}>
                        <div
                            className={'outerContainer mainContent'}
                            style={{
                                marginTop: this.state.navPresent
                                    ? false
                                    : '20px',
                            }}
                        >
                            <ErrorBoundary {...this.props}>
                                <Suspense fallback={<LoadingPage />}>
                                    <Switch>
                                        <Route exact={true} path="/">
                                            <MyPage
                                                {...this.props}
                                                onCloseSidebar={(
                                                    statusPresent
                                                ) => {
                                                    this.moodPresentation(
                                                        statusPresent
                                                    )
                                                }}
                                            />
                                        </Route>
                                        <Route path={'/colleagues'}>
                                            <Colleagues {...this.props} />
                                        </Route>
                                        <Route exact path={'/contact'}>
                                            <UpperMenuContact {...this.props} />
                                        </Route>
                                        <Route
                                            path={'/contentdossier/create/:id'}
                                        >
                                            <CreateContentDossier
                                                kedo={this.props.kedo}
                                                props={this.props}
                                            />
                                        </Route>
                                        <Route path={'/datastructure/:id'}>
                                            <DataStructureEdit
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route path={'/datastructure'}>
                                            <DataStructureIndex
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route path={'/contentdossier/:id'}>
                                            <DetailContentDossier
                                                kedo={this.props.kedo}
                                                props={this.props}
                                            />
                                        </Route>
                                        <Route path={'/subscriptions'}>
                                            <MySubscriptions {...this.props} />
                                        </Route>
                                        <Route path={'/events'}>
                                            <EventsIndex {...this.props} />
                                        </Route>
                                        <Route
                                            path={'/defdossier/conditions/:id'}
                                        >
                                            <DefDossierConditions
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route path={'/defdossier/edit/:id'}>
                                            <DefDossierEdit {...this.props} />
                                        </Route>
                                        <Route path={'/defdossier/:id'}>
                                            <DefDossierIndex {...this.props} />
                                        </Route>
                                        <Route exact path={'/profile'}>
                                            <UserProfile
                                                {...this.props}
                                                me={true}
                                            />
                                        </Route>
                                        <Route exact path="/permissions">
                                            <PermissionIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/security">
                                            <SecurityIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/logs">
                                            <LogIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/useractivity">
                                            <UserActivityIndex
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route exact path="/modules">
                                            <ModulesIndex {...this.props} />
                                        </Route>
                                        <Route exact path={['/import/:id']}>
                                            <ImportData {...this.props} />
                                        </Route>
                                        <Route exact path="/order/:id">
                                            <MyOrder {...this.props} />
                                        </Route>
                                        <Route exact path="/blocks/:id">
                                            <BlockDetail {...this.props} />
                                        </Route>
                                        <Route exact path="/blocks">
                                            <BlocksIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/reports">
                                            <Reports {...this.props} />
                                        </Route>
                                        <Route exact path="/reports/overview">
                                            <ReportsOverview {...this.props} />
                                        </Route>
                                        <Route exact path="/roles">
                                            <RolesIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/role/:id">
                                            <RoleDetail {...this.props} />
                                        </Route>
                                        <Route exact path="/user">
                                            <UserIndex {...this.props} />
                                        </Route>
                                        <Route exact path="/user/:id">
                                            <UserProfile
                                                {...this.props}
                                                me={false}
                                            />
                                        </Route>
                                        <Route
                                            path={[
                                                '/messages/:id',
                                                '/messages',
                                            ]}
                                        >
                                            <MessageOverview {...this.props} />
                                        </Route>
                                        <Route path={['/layout']}>
                                            <Layout {...this.props} />
                                        </Route>
                                        <Route path={['/files']}>
                                            <Files {...this.props} />
                                        </Route>
                                        <Route
                                            path={[
                                                '/templates/new',
                                                '/templates/:id',
                                            ]}
                                        >
                                            <TemplateForm {...this.props} />
                                        </Route>
                                        <Route path={['/templates']}>
                                            <TemplateOverview {...this.props} />
                                        </Route>
                                        <Route exact path="/wordkedocx/:id">
                                            <KedocxEditor {...this.props} />
                                        </Route>
                                        <Route path={['/querybuilder/:id']}>
                                            <QueryBuilderEditor
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route path={['/querybuilder']}>
                                            <QueryBuilderIndex
                                                {...this.props}
                                            />
                                        </Route>
                                        <Route path={['/wordkedocx']}>
                                            <KedocxIndex {...this.props} />
                                        </Route>
                                        <Route path={['/languages']}>
                                            <Languages {...this.props} />
                                        </Route>
                                        <Route exact path={'/fieldeditor/:id'}>
                                            <FieldEditor {...this.props} />
                                        </Route>
                                        <Route exact path={'/environment/edit'}>
                                            <EnvironmentEdit {...this.props} />
                                        </Route>
                                        <Route exact path={'/upgrade'}>
                                            <Upgrade {...this.props} />
                                        </Route>
                                        <Route exact path={['/webhooks']}>
                                            <WebhookIndex {...this.props} />
                                        </Route>
                                        <Route
                                            exact
                                            path={[
                                                '/module/jwwmo/toewijzingen',
                                            ]}
                                        >
                                            <ToewijzingIndex {...this.props} />
                                        </Route>
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </Col>
                </Row>
            </main>
        )
    }

    componentDidMount() {
        if (window.innerWidth <= 576 && this.props.kedo.env().getMenuStatus()) {
            localStorage.removeItem('menu')
            this.props.kedo.env().clearMenuStatus()
        }

        this.setMenuWidth()
    }

    componentDidUpdate() {
        let reset = /defdossier|contentdossier/.test(
            this.props.location.pathname
        )

        if (!reset) {
            this.props.kedo.utils().deleteSavedSearch()
            this.props.kedo.utils().deleteSavedLimit()
        }
    }

    render() {
        if (!this.props.kedo) {
            return <LoadingPage />
        }

        return (
            <div>
                <Row noGutters>
                    <div className={'d-print-none'} style={{ zIndex: '999' }}>
                        {this.getMenu()}
                    </div>
                    {this.getResponsiveContainer()}
                    <div className={'d-print-none'} style={{ zIndex: '999' }}>
                        <Favorites
                            kedo={this.props.kedo}
                            handleSidebar={this.handleSidebar}
                            open={this.state.sidebarOpen}
                            closeSidebar={this.closeSidebar}
                        />
                    </div>
                </Row>
            </div>
        )
    }
}

export default DefaultPage
