import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar'
import { toast } from 'react-toastify'

class ContentDossierFavorite extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            favorite: this.props.favorite === true ? true : false,
        }
        this.favorOrUnfavorDossier = this.favorOrUnfavorDossier.bind(this)

        this.toastOptions = {
            position: 'bottom-center',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
    }

    favor() {
        let url = this.props.kedo
            .api()
            .getContentDossierFavoriteEndpoint(this.props.dossier.id, true)
        this.setState({ loading: true })
        let data = { content_dossier: this.props.dossier.id }

        this.props.kedo
            .api()
            .post(url)
            .then((response) =>
                this.setState({
                    favorite: true,
                    loading: false,
                })
            )
    }

    favorOrUnfavorDossier() {
        if (this.state.favorite === true) {
            return this.unfavor()
        } else {
            return (
                this.favor(),
                toast.success(
                    this.props.kedo.t('dossier_errors.favorite_add'),
                    this.toastOptions
                )
            )
        }
    }

    render() {
        return (
            <Button
                active={this.state.favorite}
                size={this.props.size}
                onClick={this.favorOrUnfavorDossier}
                variant="outline-primary"
                title={this.props.kedo.t('Favorite')}
            >
                <FontAwesomeIcon icon={faStar} />
            </Button>
        )
    }
}

export default ContentDossierFavorite
